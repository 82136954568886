const baseUrl = "/zp-mobile/v1/course/"
import http from './http';
class CourseApi {
  static list(param) {
    return http.post(baseUrl + "course-list", param)
  }
  static detail(param) {
    return http.post(baseUrl + "course-detail", param)
  }
  static addFile(param) {
    return http.post(baseUrl + "add-course-file", param)
  }
  static fileList(param) {
    return http.post(baseUrl + "course-file-list", param)
  }
  static videoList(param) {
    return http.post(baseUrl + "course-video-list", param)
  }
  static saveCourseVideo(param) {
    return http.post("/zp-mobile/v1/vod/save-course-video", param)
  }
  static videoPlayInfo(param) {
    return http.post("/zp-mobile/v1/vod/video-play-info", param)
  }
  static saveCourse(param) {
    return http.post(baseUrl + "save-course", param)
  }
  static authorizeUserCourse(param) {
    return http.post(baseUrl + "authorize-user-course", param)
  }
}
export default CourseApi;