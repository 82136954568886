<template>
  <div>
    <el-page-header @back="back" content="课程详情"> </el-page-header>
    <div class="container">
      <el-descriptions border>
        <el-descriptions-item label="商品ID">
          {{courseDetail.skuId}}
        </el-descriptions-item>
        <!-- <el-descriptions-item label="课程开始时间">{{
          formatTime(courseDetail.startTime)
        }}</el-descriptions-item>
        <el-descriptions-item label="课程结束时间">{{
          formatTime(courseDetail.endTime)
        }}</el-descriptions-item> -->
      </el-descriptions>
      <h1>课程表</h1>
      <el-table border :data="courseDetail.courseCatalogList" style="margin-top: 20pxl">
        <el-table-column type="expand">
          <template slot-scope="props">
            <template v-if="props.row.catalogItemList.length">
              <h4 style="margin-left: 40px">课表详情项列表</h4>
              <el-table :data="props.row.catalogItemList" border style="margin: 0 40px">
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column prop="content" label="内容描述"></el-table-column>
                <el-table-column prop="startTime" label="开始时间">
                  <template slot-scope="scope">
                    {{ scope.row.startTime ? formatTime(scope.row.startTime) : '' }}
                  </template>
                </el-table-column>
                <el-table-column prop="endTime" label="结束时间">
                  <template slot-scope="scope">
                    {{ scope.row.endTime ? formatTime(scope.row.endTime) : ''}}
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <span v-else>暂无详情</span>
          </template></el-table-column
        >
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="content" label="内容描述"></el-table-column>
        <el-table-column prop="date" label="课程时间">
          <template slot-scope="scope">
            {{ scope.row.date ? formatTime(scope.row.date) : ''}}
          </template>
        </el-table-column>
      </el-table>
      <h1>课程资源</h1>
      <el-table border :data="courseDetail.courseResourceList">
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="subtitle" label="副标题"></el-table-column>
        <el-table-column prop="sourceId" label="资源ID"></el-table-column>
        <el-table-column prop="resourceTypeDes" label="资源类型"></el-table-column>
        <!-- <el-table-column prop="startTime" label="开始时间">
          <template slot-scope="scope">
            {{ formatTime(scope.row.startTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="endTime" label="结束时间">
          <template slot-scope="scope">
            {{ formatTime(scope.row.endTime) }}
          </template>
        </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import CourseApi from "../../request/CourseApi";
import dayjs from "dayjs";
export default {
  data() {
    return {
      skuId: null,
      courseDetail: {},
    };
  },
  mounted() {
    this.skuId = this.$route.query.skuId;
    this.loadData();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    loadData() {
      CourseApi.detail({
        skuId: this.skuId,
      }).then((res) => {
        if (res.success) {
          this.courseDetail = res.data;
        }
      });
    },
    formatTime(val) {
      return dayjs(val).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  overflow: auto;
  top: 140px;
  width: calc(100vw - 200px - 40px);
  height: calc(100vh - 140px);
}
</style>
